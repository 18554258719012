import React from "react";
import ZDrawer from "@/components/Drawer";
import FormEx from "@/components/FormEx";
import {FormItemEx} from "@/components/FormItemEx";
import {getValid} from "@/pages/createOrder/components/formInfo/helper";
import {Api} from "@/api";
import ZIcon from "@/components/Icon";
import OrderList from "@/pages/order/table";

interface Props {
    item: any
    onFinish?: any
    text?: React.ReactNode
    visible: boolean
    setVisible: any
}

const GoodsDetailDrawer = (props: Props) => {
    const {
        item = {
            sku: '',
            cn: '',
            en: '',
            usCode: '',
            weight: '',
            declare: '',
            material: '',
            brand: '',
            pic: '',
            pdf: '',
            userRemark: ''
        },
        text = '新增',
        visible = false,
        setVisible
    } = props


    // 海关编码
    const hsCode = (
        <a style={{color: '#0077FF'}} href='https://www.hsbianma.com/' target='_blank'>
            <span>海关编码</span>
            <ZIcon type='icon-search' style={{color: '#0077FF', fontWeight: 700, fontSize: '14px'}}/>
        </a>
    )

    const onFinish = (e: any) => {
        Api.Goods.SaveGoods({
            pdf: e.pdf,
            pic: e.pic,
            brand: e.brand,
            material: e.material || '无',
            declare: e.declare || '无',
            weight: e.weight,
            usCode: e.usCode,
            cn: e.cn,
            en: e.en,
            userRemark: e.userRemark,
            sku: e.sku || item.sku,
            id: text === '编辑' && item.id || undefined
        }).then(() => props?.onFinish?.())
    }


    const upload = (e: any, fn: any) => {
        const form: any = new FormData();
        form.append('file', e.target.files[0])
        form.append('sku', item.sku)
        Api.Oss.UploadSkuLabel(form).then((res: any) => fn(res.data))
    }

    const uploadImg = (e: any, fn: any) => {
        const form: any = new FormData();
        form.append('files', e.target.files[0])
        form.append('imgType', 1)
        Api.Oss.UploadImg(form).then((res: any) => fn(res?.data?.[0]))
    }
    // attr
    const attr = (label: React.ReactNode) => {
        return {
            width: 335,
            labelwidth: 90,
            label,
            filter: true,
            labelAlign: 'left'
        }
    }


    return (
        <ZDrawer visible={visible} title={(text === '复制' && '复制产品一' || '') + (item.sku || '新增产品')} width={1100}
                 mask={false} onClose={() => setVisible(false)}>
            <FormEx initialValues={item} onFinish={onFinish}>
                <div style={{width: '100%'}}>
                    <FormItemEx {...attr('图片')} fld='pic' type='upload' extra={{listType: 'picture-card'}}
                                upload={uploadImg} valid={getValid('pic')}/>
                </div>

                <FormItemEx {...attr('产品编号')} placeholder='产品编号唯一，不可重复' fld='sku' valid={getValid('sku')}/>
                <FormItemEx {...attr('中文品名')} fld='cn' required/>
                <FormItemEx {...attr('英文品名')} fld='en' required/>
                <FormItemEx {...attr('重量(kg)')} fld='weight' required/>
                <FormItemEx {...attr('单价(USD)')} fld='declare' required/>
                <FormItemEx {...attr('产品库存')} fld='stock' disabled/>
                <FormItemEx {...attr('材质(英文)')} fld='material'/>
                <FormItemEx {...attr('品牌(英文)')} fld='brand'/>
                <FormItemEx {...attr(hsCode)} fld='usCode'/>
                <FormItemEx  {...attr('上传PDF')} fld='pdf' type='upload' extra={{listType: 'picture-card'}}
                             upload={upload}/>
                <FormItemEx label='备注' labelAlign='left' fld='userRemark' type='textarea' width={1035}
                            extra={{areaHeight: 87}}
                            labelwidth={90} filter={true}/>
                <button className="primary-button f18 mt10" type='submit'
                        style={{width: 200, height: 40, marginLeft: '90px'}}>确 定
                </button>
                <div style={{minHeight: '500px', width: '100%', borderTop: '1px solid #eeeeee'}} className='mt30'>
                    <div className='mt10'>关联订单</div>
                    <OrderList params={{Goodsid: item.id}} from='goods'/>
                </div>

            </FormEx>
        </ZDrawer>
    )
}
export default GoodsDetailDrawer