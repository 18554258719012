import React, {useEffect, useState} from "react";
import ZTable from "@/components/Table";
import {IColumn} from "@/components/Table/interface";
import {ISkuData, ISkuItem} from "@/pages/createOrder/components/skuModal/interface";
import ZImage from "@/components/Image";
import {Api} from "@/api";
import {Message} from "@/components/Messsage";
import ZInput from "@/components/FormItemEx/Input";
import ZUpload from "@/components/Upload";
import Popover from "@/components/PopOver";
import './index.less'
import Modal from "@/components/Modal";
import GoodsDetailDrawer from "@/pages/account/components/goodsDetail";

interface Props {
    visible?: number
    setSelects?: any
    PageSize?: number
    from?: 'account' | 'order'
}

const GoodsTable = (props: Props) => {

    const {visible, setSelects, PageSize = 10, from = 'order'} = props
    const [data, setData] = useState<ISkuItem[]>([])
    const [total, setTotal] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [words, setWords] = useState<string>('')
    const [row, setRow] = useState<any>()
    const [open, setOpen] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('')

    const initData = (e?: string) => {
        const Keyword = e || ''
        Api.Goods.GetGoodsList({
            PageSize,
            Page: page,
            Keyword,
        }).then((res: ISkuData) => {
            if (res.state) {
                setData(res.data?.data)
                setTotal(res?.data?.total)
            }
        })
    }

    useEffect(() => {
        if (visible != -1) {
            initData(words);
        }

    }, [page, visible])
    const onSelectItem = (params: ISkuItem) => {
        if (from === 'account') {
            setRow(params)
            setMode('编辑')
            setOpen(true)
        } else {
            const newData = data.map(item => {
                if (item.id == params.id) {
                    item.isCheck = !item.isCheck;
                }
                return item;
            });
            setData(newData)
            const skus = newData.filter(item => item.isCheck)?.map((i: any) => {
                return {
                    ...i,
                    goodsId: i.id
                }
            })
            setSelects?.(skus)
        }
    }
    //
    const onCheck = (e: any, row: any) => {
        if (from === 'account') {
            e.stopPropagation()
            const newData = data.map(item => {
                if (item.id == row.id) {
                    item.isCheck = !item.isCheck;
                }
                return item;
            });
            setData(newData)
            const skus = newData.filter(item => item.isCheck)?.map((i: any) => {
                return {
                    ...i,
                    goodsId: i.id
                }
            })
            setSelects?.(skus)
        }
    }
    const onDelete = (item: ISkuItem) => {
        Modal.confirm({
            title: "确认删除该商品？",
            onOk: () => {
                Api.Goods.DeleteGoods({id: item.id as number}).then(() => {
                    Message.success('操作成功');
                    initData()
                })
            }
        });
    }
    const onDeleteBatch = () => {
        const ids = data.filter(item => item.isCheck).reduce((result: number[], i: ISkuItem) => {
            result.push(i.id as number)
            return result
        }, []).join(',');
        if (!ids) {
            return
        }
        Modal.confirm({
            title: "确认批量删除商品？",
            onOk: () => {
                Api.Goods.BatchDelete({ids}).then(() => {
                    Message.success('操作成功');
                    initData()
                })
            }
        });
    }

    const column: IColumn[] = [
        {
            width: 60,
            render: (item: string, record: ISkuItem) =>
                <div style={{width: '100%', height: '50px'}} onClick={(e: any) => onCheck(e, record)}>
                    <input type='checkbox' checked={record.isCheck}

                           style={{
                               zoom: 1.4,
                               cursor: 'pointer',
                               marginLeft: '10px'
                           }}/>
                </div>

        },
        {
            title: '图片', key: 'pic', width: 100, render: (text: string) =>
                <Popover key={text} content={<img src={text} style={{maxWidth: '800px', maxHeight: '800px'}}/>}>
                    <ZImage src={text} className='icon64'/>
                </Popover>
        },
        {
            title: '产品名称',
            key: 'cn',
            width: 190,
            render: (text: string, row: any) =>
                <div>
                    <div className='mb10'>{text}</div>
                    <div className='color-999'>{row.en}</div>
                </div>
        },
        {
            title: '单价 & 重量',
            key: 'declare',
            width: 120,
            render: (text: number, row: any) =>
                <div>
                    <div className='mb10'>${text.toFixed(2)}</div>
                    <div className='color-999'>{row.weight.toFixed(2)}kg</div>
                </div>
        },
        {
            title: '产品编号 & 海关编码',
            key: 'sku',
            width: 280,
            render: (text: string, row: any) =>
                <div style={{height: '100%'}}>
                    <div className='mb10'>{text}</div>
                    <div className='color-999'>{row.usCode}</div>
                </div>
        },
        //
        {
            title: '库存 & 关联订单',
            key: 'stock',
            render: (text: string, row: any) =>
                <div>
                    <div className='mb10 color-green'>{text}</div>
                    <div className='color-blue pointer'>{row.lastShipment}</div>
                </div>
        },
        {
            title: '操作', width: 70, key: '', render: (_: any, row: any) =>
                <div className='flex flex-column' onClick={(e: any) => e.stopPropagation()}>
                    <a className='mb10' onClick={() => onDelete(row)}>删除</a>
                    <a onClick={() => onEdit(row, from === 'order' && '编辑' || '复制')}>{from === 'order' && '编辑' || '复制'}</a>
                </div>
        }
    ]


    useEffect(() => {
        if (visible === -1) {
            const selectDatas = data.reduce((result: ISkuItem[], item: ISkuItem, index: number) => {
                result[index] = {
                    ...item,
                    isCheck: false
                }
                return result
            }, []);
            setData(selectDatas)
        }
    }, [visible])

    const onChange = (e: any) => {
        if (!e && !!e != !!words) {
            initData();
        }
        setWords(e)
    }

    const upload = (e: any) => {
        const form: any = new FormData();
        form.append('file', e.target.files[0])
        Api.Goods.UploadGoods(form).then((res: any) => {
            const json = res.data.map((item: any) => {
                return {
                    ...item,
                    new: true
                }
            })
            setData([
                ...json,
                ...data
            ])
            Message.success('导入成功');
        })
    }
    const onKeyUp = (e: any) => {
        if (e.keyCode === 13 && words) {
            initData(words)
        }
    }
    // 新增/编辑
    const onEdit = (e: any, mode?: string) => {
        setRow(e)
        setOpen(true)
        setMode(mode as string)
    }
    return (
        <>
            <div className="flex justify-between" style={{height: '32px'}}>
                <div className="flex align-items-center">
                    <button className="ghost-button mr10" style={{height: '32px'}} onClick={() => onEdit({})}>新增商品
                    </button>
                    <button className="grey-button mr10" style={{height: '32px'}} onClick={onDeleteBatch}>批量删除</button>
                    <ZUpload upload={upload}>
                        <button className="primary-button mr10" style={{height: '32px'}}>商品批量导入</button>
                    </ZUpload>
                    <a className='color-2c6ecb'
                       href="http://fbatop.com/Upload/Template/%E5%A4%B4%E7%A8%8B%E5%AE%9D-%E4%BA%A7%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF3.xlsx"
                       download>下载模板</a>
                </div>
                <div className="flex flex1">
                    <ZInput placeholder="请输入产品中文名称" value={words} onChange={onChange} width={150} onKeyUp={onKeyUp}/>
                    <button className="primary-button" style={{borderRadius: '0', marginLeft: '-1px'}}
                            onClick={() => initData(words)}>查询
                    </button>
                </div>
            </div>
            <ZTable
                onRowClick={onSelectItem}
                vertical="top"
                column={column}
                data={data}
                pagination
                total={total}
                pageSize={PageSize}
                rowClass={(e: any) => e.new && 'goods-import-new' || ''}
                onChange={setPage}/>
            <GoodsDetailDrawer item={row} text={mode} visible={open} setVisible={setOpen} onFinish={initData}/>
        </>

    )
}
export default GoodsTable