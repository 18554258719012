import {getBoxDtoList, getTotalData} from "@/pages/createOrder/interface";
// 默认sku
export const skuDefault = {
    '0-brand-0': "",
    '0-brandType-0': '境内自主',
    '0-cn-0': "",
    '0-declare-0': "",
    '0-en-0': "",
    '0-material-0': "",
    '0-model-0': "",
    '0-num-0': "",
    '0-origin-0': "CN",
    '0-sku-0': "",
    '0-usCode-0': "",
    '0-useage-0': "",
    '0-weight-0': "",
    '0-specAddPirce-0': "",
    '0-goodsId-0': 0,
}

// form 默认一个箱子
export const boxDefault = {
    '0-high': '',
    '0-long': '',
    '0-num': 1,
    '0-weight': '',
    '0-wide': '',
    ...skuDefault
}

const blurParamsMap: string[] = ['high', 'long', 'num', 'weight', 'wide']
export const boxBlur = (e: any) => {
    if (!Object.keys(e)?.length) {
        return
    }
    const params: string[] = Object.keys(e)[0].split?.('-')
    return (params.length === 2 && blurParamsMap.includes(params[1]))
}



//
// @ts-ignore
export const validGetPrice = (fields: any) => {
    const box: any = getBoxDtoList(fields)
    return box.reduce((result: any, item: any) => {
        blurParamsMap.forEach((key: string) => {
            if (!item[key]) {
                result = false
            }
        })
        return result
    }, true)
}

export const getValid = (fld?:string)=>{
    if (!fld) {
        return undefined
    }
    if (fld.includes('weight') || fld.includes('declare')) {
        return 'money'
    } else if (fld.includes('usCode')) {
        return 'hsCode'
    } else if (fld.includes('num')) {
        return 'integer'
    } else {
        return undefined;
    }
}

export const validDataMap = (fld?:string)=>{
    if(fld){
        if(fld.includes('en')){  return '请输入英文'}
        if (fld.includes('cn')) {
            return '请输入中文'
        }
        if (fld.includes('sku')) {
            return '请输入产品编号'
        }
        if (fld.includes('usCode')) {
            return '请输入海关编码'
        }
        if (fld.includes('weight')) {
            return '输入产品重量'
        }
        if (fld.includes('declare')) {
            return '输入申报单价'
        }
        if (fld.includes('num')) {
            return '单箱件数'
        }
        if (fld.includes('material')) {
            return '输入材质'
        }
        return
    }else{
        return '请完善'
    }

}


// 获取table属性
export const getAttr = (params:{superior: any,record: any,field?: string}) => {
    const {superior,record,field} = params
    return {
        hideChart: true,
        hideLabel: true,
        extra: {bordered: 'none'},
        size: 'small',
        fld: `${superior.id}-${field}-${record.id}`,
        valid: getValid(field) as any,
        validMessage: validDataMap(field) as any,
        validAlign: 'center' as 'center',
        align: 'center' as 'center',
        mode: 'table' as 'table'
    }
}

// 添加箱类型
export const boxAddHelper = (params:{box: any,type?: any}) => {

    const {box, type = boxDefault} = params
    let id = Number(box[box.length - 1]?.id) + 1 // 箱子id主键递增
    return Object.keys(type).reduce((result: any, i: string) => {
        const key: string = i.replace(`0-`, `${id}-`)
        result[key] = type[i]
        return result
    }, {})
}

// 删除箱
export const boxDeleteHelper = (params:{fields: any,item: any}) => {
    const {fields,item} = params
    return Object.keys(fields).reduce((result: any,i: string) => {
        if(!i.includes(`${item.id}-`)){
            result[i] = fields[i]
        }
        return result
    },{})
}

// 添加sku
export const skuAddHelper = (sub: any,item: any) => {
    const len = sub[sub.length - 1].id + 1
    return Object.keys(skuDefault).reduce((result: any,i: string) => {
        const key: string = i.replace('-0',`-${len}`).replace('0-',`${item.id}-`)
        result[key] = boxDefault[i]
        return result
    },{})
}

// 删除 sku
export const skuDeleteHelper = (params:{fields: any,item: any,subItem: any}) => {
    const {fields, item, subItem} = params;
    const json = Object.keys(fields).reduce((result: any, i: string) => {
        if (i.includes(`${item.id}-`)) {
            if (!i.includes(`-${subItem.id}`)) {
                result[i] = fields[i]
            }
        } else {
            result[i] = fields[i]
        }
        return result
    }, {})
    return json
}

// 选择sku
export const skuSelectHelper = (params: {sku: any,boxList: any,key: number}) => {

    const {sku, boxList, key} = params;
    const current = boxList.find((i: any) => i.id === key)
    const boxJson = current.productDtos.filter((i: any) => i.sku)

    const skus: string[] = boxJson.reduce((result: string[], item: any) => {
        result.push(item.sku)
        return result.filter((i: any) => i)
    }, [])

    const selectDatas = sku.reduce((result: any, item: any, index: number) => {
        if (skus.includes(item.sku)) {
            result[index] = {
                ...item,
                id: boxJson.find((i: any) => i?.sku === item.sku).id
            }
        } else {
            result[index] = {
                ...item,
                id: skus.length ? Math.max(boxJson[boxJson.length - 1].id, (result[result.length - 1]?.id ?? 0)) + 1 : index
            }
        }
        return result
    }, [])
    const selectedSkus =
        selectDatas.reduce((result: any, i: any) => {
            Object.keys(i)?.forEach((j: string) => result[`${key}-${j}-${i.id}`] = i[j])
            return result
        }, {})
    return selectedSkus
}

// 获取最终报价
export const onSubmit = (e: any) => {
    const json = {...e, ...getTotalData(e)}
    const {
        box,
        phone,
        street,
        corp,
        name,
        areaCode,
        referenceId,
        shipmentId,
        weight,
        quantity,
        deliverType,
        templateName,
        templateId,
        method,
        addressId,
        wareCode,
        memo
    } = json
    return {
        memo,
        addressId,
        weight,
        quantity,
        box,
        wareCode,
        deliverType,
        templateName,
        templateId,
        method,
        referenceId,
        shipmentId,
        topSender: {
            phone,
            street,
            corp,
            name,
            code: areaCode,
        },
        boxDtoList: getBoxDtoList(e).reduce((result: any, item: any) => {
            result.push(
                {
                    ...item,
                    high: Math.ceil(Number(item.high)),
                    wide: Math.ceil(Number(item.wide)),
                    long: Math.ceil(Number(item.long)),
                    productDtos: item.productDtos.filter((i: any) => i)
                }
            )
            return result
        }, [])
    }
}
